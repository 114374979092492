import React from 'react'
import { PageProps } from 'gatsby'
import { Head, Button, Footer, Lazyload } from '~/components'
import * as st from '~/assets/styl/NotFound.module.styl'
import Banner from '~/assets/img/Agradecimento.jpg'

const Success = ({ location }: PageProps) => (
  <>
    <Head
      location={location}
      title={'Mensagem enviada - ' + process.env.GATSBY_SITE_NAME}
    />
    <header className={st.core}>
      <div className={st.container}>
        <div className={st.box}>
          <Lazyload src={Banner} className={st.img} />
          <div className={st.text}>
            <h1>Mensagem enviada</h1>
            <p style={{ maxWidth: 'none' }}>
              Sua mensagem foi enviada com sucesso.
              <br />
              Em breve entraremos em contato.
            </p>
            <Button href="/" className={st.btn}>
              Voltar
            </Button>
          </div>
        </div>
      </div>
    </header>
    <Footer />
  </>
)

export default Success
